const  DashboardDynamic = (params) => {
    console.log(params)
    const src_str = `REACT_APP_DASHBOARD_${params.did}_URL`
    const src = process.env[src_str]
    return (
            <iframe
                style={{width: '90%', height: '1500px'}}
                src={src}>
            </iframe>
    )
}
export default DashboardDynamic;